import React, { FC, memo, useEffect, useState } from 'react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  ExportOrderPlanDocument,
  OrderPlannerDocument,
} from '$typings/graphql-codegen';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { ensureNumber, roundDownToNearestHundred } from '$lib/numberHelpers';
import { convertByUnit } from '$lib/unitConverters';
import { PlannerGrid } from '../common/planner-grid';
import { Button, InputAdornment } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { NumberInput } from '$components/inputs/number-input/number-input.react';
import { formatDate, pureFormatDate } from '$lib/dateHelpers';
import { mutate } from '$lib/hooks/fetch-utillities';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import { useQuantities } from './utils/useQuantities';
import { OrderPlanner } from '$typings/graphql';

interface IOrderListProps {
  selectedSiteChannelIds: string[];
  addedSelectedSiteChannelIds: string[];
}

const getQuantityValueFromFreeCapacity = (
  freeCapacity: number | undefined | null
) => {
  const result = freeCapacity ? roundDownToNearestHundred(freeCapacity) : 0;
  return result > 0 ? result : 0;
};

const getQuantityInTonnes = (
  quantity: number,
  density?: number,
  unitSymbol?: string
) => {
  return convertByUnit(quantity, density, unitSymbol);
};

const getSummary = async (
  // wrapped in a promise because it might be a big calculation
  quantities: Map<number, number>,
  selectedSiteChannels: number[],
  data?: OrderPlanner['siteChannels']['edges']
) => {
  if (!data) {
    return new Promise<Map<string, string>>((_, reject) => reject('no data'));
  }
  const channelsToSum = data.filter((channel) =>
    selectedSiteChannels.includes(channel.siteChannelId)
  );

  const summaryValues = new Map<string, string>();

  return new Promise<Map<string, string>>((resolve) => {
    const quantitySum = Array.from(quantities.entries())
      // quantities only contians selected site channels
      .reduce((sum, [, quantity]) => sum + quantity, 0)
      .toString();

    const freecapSum = Math.round(
      channelsToSum.reduce(
        (sum, item) => sum + (item.tankDetails?.freeCapacity ?? 0),
        0
      ) ?? 0
    ).toString();

    const tonnesSum =
      channelsToSum
        .reduce(
          (sum, item) =>
            sum +
            ensureNumber(
              getQuantityInTonnes(
                quantities.get(item.siteChannelId) ??
                  getQuantityValueFromFreeCapacity(
                    item.tankDetails?.freeCapacity
                  ),
                item.product?.density ?? undefined,
                item.unit?.symbol
              ) ?? 0
            ),
          0
        )
        ?.toFixed(2)
        .toString() ?? '0';

    summaryValues.set('quantity', quantitySum);
    summaryValues.set('freecap', freecapSum);
    summaryValues.set('tonnes', tonnesSum);

    return resolve(summaryValues);
  });
};

type ExportStatus = 'not_started' | 'running' | 'success' | 'error';

interface IDownloadIconData {
  textKey: string;
  icon: string;
}

//prettier-ignore
const downloadIconState = new Map<ExportStatus, IDownloadIconData>([
  ['not_started', { textKey: 'UI_SiteList_OrderPlanner_ExportAsZip', icon: 'fa-download' }],
  ['success', { textKey: 'UI_SiteList_OrderPlanner_ExportAsZip', icon: 'fa-check' }],
  ['error', { textKey: 'UI_Common_Try_Again', icon: 'fa-download' }],
  ['running', { textKey: 'UI_SiteList_OrderPlanner_ExportAsZip', icon: 'fa-pulse fa-spinner' }]
]);

const OrderTableComponent: FC<IOrderListProps> = ({
  selectedSiteChannelIds,
  addedSelectedSiteChannelIds,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedSiteChannels, setSelectedSiteChannels] = useState<number[]>([]);
  const [quantities, setQuantity, getQuantity, updateQuantities] = useQuantities();
  const [exportStatus, setExportStatus] = useState<ExportStatus>('not_started');

  const { data } = useGridData(
    OrderPlannerDocument,
    { siteChannelIds: selectedSiteChannelIds },
    (data) => data.siteChannels.totalCount,
    (data) => data.siteChannels.edges
  );

  const updateSelectedSiteChannels = () => {
    if (!data) {
      return;
    }

    // data has all available sitechannels filtered by selected sites.
    const filteredSelectedSiteChannels = data
      .map((s) => s.siteChannelId)
      .filter((siteChannelId) => selectedSiteChannels.includes(siteChannelId));

    // find newly added sitechannelids based on addedSelectedSiteChannelIds
    const addedSiteChannels = data
      .filter(
        (siteChannel) =>
          !siteChannel.isParked 
            && addedSelectedSiteChannelIds.map(id => id.toString()).includes(siteChannel.siteChannelId.toString())
      ) // add new site channels, but exclude parked
      .map((s) => s.siteChannelId)
      .filter((id) => !filteredSelectedSiteChannels.includes(id));

    const updated = [...filteredSelectedSiteChannels, ...addedSiteChannels];

    const old = JSON.stringify([...selectedSiteChannels].sort());
    const _new = JSON.stringify([...updated].sort());
    const isChanged = old !== _new;
    if (isChanged) {
      setSelectedSiteChannels(updated);
    }
  };

  useEffect(() => {
    updateQuantities(
      data as OrderPlanner['siteChannels']['edges'],
      selectedSiteChannels
    );
    exportStatus !== 'running' && setExportStatus('not_started');
  }, [selectedSiteChannels]);

  useEffect(() => {
    updateSelectedSiteChannels();
  }, [data]);

  const getAllSelectableRowIds = () => {
    return data?.map((sc) => sc.siteChannelId) ?? [];
  };

  const summaryValues = getSummary(
    quantities,
    selectedSiteChannels,
    data as OrderPlanner['siteChannels']['edges']
  );

  const getError = (
    row: OrderPlanner['siteChannels']['edges'][0]
  ): string | undefined => {
    const errors: string[] = [];

    if (!row.tankDetails?.vehicleId) errors.push('vehicle');
    if (!row.site?.projectCode) errors.push('projectcode');
    if (!row.site?.soldTo) errors.push('sold-to');
    if (!row.product?.staalduinenProductMap) errors.push('staalduinen mapping');
    if (!row.product?.density) errors.push('density for product');

    return errors.length ? 'Missing ' + errors.join(', ') : undefined;
  };

  const dataWithErrorField = data?.map((item) => ({
    ...item,
    error: getError(item as OrderPlanner['siteChannels']['edges'][0]),
  }));

  const exportOrderTable = async () => {
    if (!data) return;
    setExportStatus('running');

    const selectedData = data.filter((sc) =>
      selectedSiteChannels.includes(sc.siteChannelId)
    );

    const payload = selectedData
      .map((sc) => ({
        siteId: parseInt(sc.site?.siteId ?? '', 10),
        tankId: parseInt(sc.tankDetails?.tankId ?? '', 10),
        quantity: quantities.get(sc.siteChannelId) ?? 0,
      }))
      .filter((a) => !!a.quantity);

    await mutate(
      ExportOrderPlanDocument,
      { order: payload },
      false,
      (result) => {
        if (result.exportOrder.success) {
          const url = result.exportOrder.message;
          downloadFileFromUrl(
            url,
            `StaalduinenOrders_${formatDate(new Date(), false, '-')}.zip`
          );
          setExportStatus('success');
        } else {
          setExportStatus('error');
        }
      },
      () => {
        setExportStatus('error');
      },
      0
    );
  };

  return (
    <PlannerGrid
      name={'order-planner'}
      getRowKey={(d) => d?.siteChannelId}
      data={dataWithErrorField}
      selected={selectedSiteChannels}
      setSelected={setSelectedSiteChannels}
      getAllSelectableRowIds={getAllSelectableRowIds}
      summaryFields={['quantity', 'freecap', 'tonnes']}
      summaryValues={summaryValues}
      footerClass={exportStatus === 'error' ? 'footer-error' : ''}
      footerText={
        exportStatus === 'error'
          ? t('UI_SiteList_Delivery_ExportError')
          : t('ui_planner_tank_selected', {
              count: selectedSiteChannels.length,
            })
      }
      footerActionButtons={
        <Button
          disabled={!selectedSiteChannels.length || exportStatus === 'running'}
          variant={'contained'}
          startIcon={
            <Icon name={downloadIconState.get(exportStatus)?.icon ?? ''} />
          }
          onClick={exportOrderTable}
        >
          {t(downloadIconState.get(exportStatus)?.textKey ?? '')}
        </Button>
      }
      columns={[
        {
          columnKey: 'sitename',
          columnTitle: t('ui_planner_table_header_site_name'),
          render: (item, width) => (
            <GridCell width={width}>{`${item.site?.alias}`}</GridCell>
          ),
          initialWidth: 250,
        },
        {
          columnKey: 'product',
          columnTitle: t('ui_planner_table_header_product'),
          render: (item, width) => (
            <GridCell width={width}>
              {`${item.product?.name ?? 'No product'}`}
            </GridCell>
          ),
          initialWidth: 100,
        },
        {
          columnKey: 'freecap',
          columnTitle: t('ui_planner_table_header_free_capacity'),
          render: (item, width) => (
            <GridCell width={width}>
              {Math.round(item.tankDetails?.freeCapacity ?? 0)}
            </GridCell>
          ),
          initialWidth: 80,
        },
        {
          columnKey: 'quantity',
          columnTitle: t('ui_planner_table_header_quantity'),
          render: (item, width, selected) => {
            const value = getQuantity(
              item.siteChannelId,
              item.tankDetails?.freeCapacity ?? undefined
            );
            const hasError = item.tankDetails?.freeCapacity
              ? value > Math.round(item.tankDetails?.freeCapacity ?? 0)
              : false;
            return (
              <GridCell
                key={'quantity_input_' + item.siteChannelId}
                width={width}
                style={{
                  padding: 0,
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <NumberInput
                  numberType={'integer'}
                  disabled={!selected}
                  min={0}
                  error={hasError}
                  InputProps={
                    !selected
                      ? undefined
                      : {
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon
                                title={
                                  hasError
                                    ? t(
                                        'UI_SiteList_Delivery_Quantity_OutOfRange'
                                      )
                                    : undefined
                                }
                                name={
                                  hasError
                                    ? 'fa-exclamation-triangle'
                                    : 'fa-check'
                                }
                              />
                            </InputAdornment>
                          ),
                        }
                  }
                  value={selected ? value : ''}
                  setValue={(value) => {
                    const valueAsNumber = parseInt(value);
                    if (isNaN(valueAsNumber)) {
                      setQuantity(item.siteChannelId, 0);
                    } else {
                      setQuantity(item.siteChannelId, valueAsNumber);
                    }
                  }}
                />
              </GridCell>
            );
          },
          initialWidth: 100,
        },
        {
          columnKey: 'tonnes',
          columnTitle: t('ui_planner_table_header_tonnes'),
          render: (item, width, selected) => (
            <GridCell width={width}>
              {selected ? getQuantityInTonnes(
                getQuantity(
                  item.siteChannelId,
                  item.tankDetails?.freeCapacity ?? undefined
                ),
                item.product?.density ?? undefined,
                item.unit?.symbol
              )?.toFixed(2) : ''}
            </GridCell>
          ),
          initialWidth: 80,
        },
        {
          columnKey: 'totalcap',
          columnTitle: t('ui_planner_table_header_total_capacity'),
          render: (item, width) => (
            <GridCell width={width}>{Math.round(item.capacity ?? 0)}</GridCell>
          ),
          initialWidth: 80,
        },
        {
          columnKey: 'reachempty',
          columnTitle: t('ui_planner_table_header_reach_empty'),
          render: (item, width) => (
            <GridCell width={width}>
              {item.tankDetails?.reachEmpty &&
                pureFormatDate(item.tankDetails?.reachEmpty, false, '.')}
            </GridCell>
          ),
          initialWidth: 135,
        },
      ]}
    />
  );
};

export const OrderTable = memo(OrderTableComponent) as typeof OrderTableComponent;
