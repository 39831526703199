import { useState } from 'react';
import { roundDownToNearestHundred } from '$lib/numberHelpers';
import { OrderPlanner } from '$typings/graphql';

const getQuantityValueFromFreeCapacity = (
  freeCapacity: number | undefined | null
) => {
  const result = freeCapacity ? roundDownToNearestHundred(freeCapacity) : 0;
  return result > 0 ? result : 0;
};

type UseQuantitiesReturn = [
  quantities: Map<number, number>, 
  setQuantity:(siteChannelId: number, value: number) => unknown, 
  getQuantity: (siteChannelId: number, freeCapacity?: number | null) => number,
  updateQuantities: (data: OrderPlanner['siteChannels']['edges'], selectedSiteChannels: number[]) => unknown
]

export const useQuantities = () : UseQuantitiesReturn => {
  const [quantities, setQuantities] = useState(new Map<number, number>());


  const setQuantity = (siteChannelId: number, value: number) => {
    const mutatedMap = new Map(quantities);
    mutatedMap.set(siteChannelId, value);
    setQuantities(mutatedMap);
  };


  const getQuantity = (siteChannelId: number, freeCapacity?: number | null) => {
    const value = quantities.get(siteChannelId);

    if (value === undefined) {
      return getQuantityValueFromFreeCapacity(freeCapacity);
    }

    return value;
  };

  const updateQuantities = (data: OrderPlanner['siteChannels']['edges'], selectedSiteChannels: number[]) => {
    const siteChannels = data?.filter((channel) =>
      selectedSiteChannels.includes(channel.siteChannelId)
    );
    if (!siteChannels) return;

    const newQuantities = new Map<number, number>();

    // add entries for new ids
    siteChannels.forEach((channel) => {
      // extract old relevant values and calculate new initial value for new siteChannels
      const quantity = getQuantity(
        channel.siteChannelId,
        channel.tankDetails?.freeCapacity
      );
      newQuantities.set(channel.siteChannelId, quantity);
    });

    setQuantities(newQuantities);
  };
  
  return [quantities, setQuantity, getQuantity, updateQuantities];
}
