import React, { FC, memo,useState } from 'react';
import { PlannerPage } from '../common/planner-page';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { OrderTable } from './order-planner-grid';

interface IOrderPlannerProps {}

const OrderPlannerComponent: FC<
  IOrderPlannerProps
> = ({}: IOrderPlannerProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedSiteChannelIds, setSelectedSiteChannelIds] = useState<string[]>([]);
  const [addedSelectedSiteChannelIds, setAddedSelectedSiteChannelIds] = useState<string[]>([]);

  const updateSelectedSites = (siteChannelIds: string[]) => {
    const added = siteChannelIds.filter((s) => !selectedSiteChannelIds.includes(s));
    setAddedSelectedSiteChannelIds(added);
    setSelectedSiteChannelIds(siteChannelIds); 
  };

  return (
    <PlannerPage
        name={"order"}
        header={t('UI_SiteList_OrderPlanner_Title')}
        selectedSitesChanged={updateSelectedSites}
        select='siteButTanks'
      >
        {selectedSiteChannelIds.length > 0 ? (
          <OrderTable
            selectedSiteChannelIds={selectedSiteChannelIds}
            addedSelectedSiteChannelIds={addedSelectedSiteChannelIds}
          />
        ) : (
          <div className="mar_m">
            {t('UI_SiteList_Delivery_NoSitesSelected')}
          </div>
        )}
    </PlannerPage>
  );
};

export const OrderPlanner = memo(OrderPlannerComponent);
